<template>
  <TitleContentPanel title="产业分布" height="100%">
    <template #append>
      <span @click="showDesc = true">详情</span>
    </template>

    <div class="left-3-wrapper">
      <div class="chart-dom" ref="chartDom"></div>
      <!-- <VEchart :option="option" @legendselectchanged="handleChartClick" /> -->
    </div>

    <template #reset>
      <el-button type="text" @click="handleReset">重置</el-button>
    </template>
  </TitleContentPanel>

  <Modal :isShow="showDesc">
    <IndustryTagChart @onClose="handleModalClose" />
  </Modal>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import IndustryTagChart from './IndustryTagChart'
import Modal from '@/components/Modal'
import { ref, watch, onMounted, computed } from 'vue'
import { chartColors } from '@/config/commonConfig'
import { aggregateList } from '@/api/aggregate2'
import {
  SET_CURRENT_INDUSTRY_TAG,
  SET_CURRENT_INDUSTRY_CODE,
  SET_CURRENT_INDUSTRY_LABEL
} from '@/store/mutation-types'
import { useStore } from 'vuex'
import * as echarts from 'echarts'
import { formatTax } from '@/utils/util'
import dbMap from '@/api/dbMap'
export default {
  name: 'Left3',
  components: { TitleContentPanel, Modal, IndustryTagChart },
  setup: () => {
    const store = useStore()
    const showDesc = ref(false)
    const chartDom = ref()
    const currentCompanyKey = computed(() => store.state.cloudMap.currentCompanyKey)
    let chartData, myChart
    const option = ref({
      backgroundColor: '#1a1a1a',
      color: chartColors,
      grid: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        show: true,
        backgroundColor: '#1a1a1a',
        borderColor: '#1a1a1a'
      },
      tooltip: {
        // trigger: 'item'
        show: false
      },
      legend: {
        itemWidth: 10,
        itemHeight: 10,
        type: 'scroll',
        orient: 'vertical',
        left: 10,
        top: 10,
        bottom: 20,
        icon: 'circle',
        textStyle: { color: '#fff' }
      },
      series: [
        {
          minAngle: 5,
          name: '产业分布',
          type: 'pie',
          center: ['70%', '40%'],
          radius: ['60%', '70%'],
          label: {
            show: false,
            formatter: '{b}\n{d}%',
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: []
        }
      ]
    })

    const params = {
      count: {
        cmdList: [
          { $unwind: '$data.cpyTag' },
          {
            $group: {
              _id: '$data.cpyTag',
              counts: { $sum: 1 },
              tax: { $sum: '$stats.lastYearTax' }
            }
          },
          {
            $match: {
              _id: {
                $in: [
                  '总部经济',
                  '航运贸易',
                  '文化旅游',
                  '商贸商务',
                  '数字经济',
                  '现代金融',
                  '其它'
                ]
              }
            }
          }
        ],
        templateName: dbMap.B_CONSOLE_COMPANY
      }
    }

    onMounted(async () => {
      myChart = echarts.init(chartDom.value)
      chartData = await getChartData()
      option.value.series[0].data = chartData
      myChart.setOption(option.value)

      myChart.on('legendselectchanged', e => {
        store.commit(SET_CURRENT_INDUSTRY_CODE, '')
        store.commit(SET_CURRENT_INDUSTRY_LABEL, '')
        store.commit(SET_CURRENT_INDUSTRY_TAG, e.name)
        option.value.legend.selected = Object.keys(e.selected).map(d => ({
          [d]: true
        }))
        myChart.setOption(option.value)
      })
    })

    const getChartData = async () => {
      const sort = ['总部经济', '数字经济', '现代金融', '商贸商务', '航运贸易', '文化旅游', '其它']
      const res = await aggregateList(params)

      const count = res.result.count
      console.log(count)
      const result = count.map(d => {
        return {
          name: d.id,
          value: d.counts,
          counts: d.counts,
          tax: formatTax(d.tax)
        }
      })

      const sortArr = []
      result.forEach((d, i) => {
        const index = sort.findIndex(s => s === d.name)
        sortArr[index] = d
      })

      return sortArr
    }

    watch(currentCompanyKey, key => {
      chartData = chartData.map(d => {
        if (key === 0) {
          d.value = d.counts
        } else if (key === 1) {
          d.value = d.tax
        }
        return d
      })
      option.value.series[0].data = chartData
      myChart.setOption(option.value)
    })

    const handleModalClose = () => {
      showDesc.value = false
      store.commit(SET_CURRENT_INDUSTRY_TAG, '')
    }

    const handleReset = () => {
      store.commit(SET_CURRENT_INDUSTRY_TAG, '')
    }

    return { option, showDesc, chartDom, handleModalClose, handleReset }
  }
}
</script>

<style lang="less" scoped>
.left-3-wrapper {
  height: 100%;

  .chart-dom {
    height: 100%;
  }
}
</style>
