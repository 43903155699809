<template>
  <TitleContentPanel title="企业概况" height="100%">
    <template #append>
      <span @click="handleDesc">经济概况</span>
    </template>

    <div class="left-1-wrapper">
      <div class="total-count">
        <span class="text">企业总数</span>
        <!-- <span class="count">{{ dataObj.totalCount }}</span> -->
        <CountTo class="count" :startVal="0" :endVal="dataObj.totalCount" :duration="1000" />
        <span class="text">家</span>
      </div>

      <div class="key1-key2-key3">
        <LabelCount class="label-count" label="个体工商户(家)" :count="dataObj.individual" />
        <LabelCount class="label-count" label="公司机构(家)" :count="dataObj.company" />
        <LabelCount class="label-count" label="企业总税收(万元)" :count="dataObj.tax" />
      </div>

      <!-- <div class="key4-key5-key6">
        <LabelCount class="label-count" label="上市企业" :count="dataObj.key4" />
        <LabelCount class="label-count" label="高新技术企业" :count="dataObj.key5" />
        <LabelCount class="label-count" label="纳税A级企业" :count="dataObj.key6" />
      </div> -->
    </div>
  </TitleContentPanel>

  <Modal :isShow="dataObj.showDesc">
    <div class="desc-title">
      <div class="title-close">
        <span class="title">市南区经济概况</span>
        <div class="net-admin-btn">
          <Icon class="close" name="close" @click="dataObj.showDesc = false" />
        </div>
      </div>

      <div class="desc-content">
        <div class="part">
          {{ part }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import LabelCount from '@/components/ListView/LabelCount'
import TitleContentPanel from '@/components/TitleContentPanel'
import { CountTo } from 'vue-count-to2'
import { aggregateList } from '@/api/aggregate2'
import { ref, reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal'
import Icon from '@/components/IconSvg'
// import { getIndustryListLevel0 } from '@/api/list'
export default {
  name: 'Left1',
  components: { LabelCount, TitleContentPanel, CountTo, Modal, Icon },
  setup: () => {
    const store = useStore()
    const currentIndustryCode = computed(() => store.state.common.currentIndustryCode)
    const currentIndustryTag = computed(() => store.state.common.currentIndustryTag)

    const part = ref(
      '市南区2022年生产总值1496.36亿元，同比增长4.7%；社会消费品零售总额720.8亿元。加快新旧动能转换，总部经济、时尚商业、金融商务、现代旅游等发展迅速，第三产业增加值1340.38亿元，同比增长3.6%；三产占比89.6%，位居十区市首位。作为全省面积最小的县（市区），以全市2.7‰的土地，实现了全市10%的经济总量，每平方公里生产总值达49.9亿元，居副省级城市中心城区前列。'
    )

    const dataObj = reactive({
      showDesc: false,
      totalCount: 0, // 企业总数
      regCpyCount: 0, // 注册企业数
      entCpyCount: 0, // 入驻企业数
      regEntCpyCount: 0, // 入驻且注册
      individual: 0, // 个体户
      company: 0, // 公司
      tax: 0 // 总税收
    })

    const fetchData = (opts = {}) => {
      const { industryCode, industryTag } = opts

      const params = {
        totalCount: {
          cmdList: [
            {
              $match: {
                'data.regStatusLabel': '在营（开业）企业',
                'data.type': { $in: ['企业', '个体户'] }
              }
            },
            { $count: 'count' }
          ],
          templateName: 'b_console_cpy'
        },
        individual: {
          // cmdList: [{ $match: {} }, { $match: { 'data.copyType': '26' } }, { $count: 'count' }],
          cmdList: [
            // { $match: { 'data.cpyTypeLabel': { $exists: true } } },
            { $match: { 'data.regStatusLabel': '在营（开业）企业', 'data.type': '个体户' } },
            { $group: { _id: '$data.type', count: { $sum: 1 } } }
          ],
          templateName: 'b_console_cpy'
        },
        company: {
          cmdList: [
            { $match: { 'data.regStatusLabel': '在营（开业）企业', 'data.type': '企业' } },
            { $count: 'count' }
          ],
          templateName: 'b_console_cpy'
        },
        tax: {
          cmdList: [
            { $match: {} },
            { $group: { _id: '', totalTax: { $sum: '$stats.lastYearTax' } } },
            { $project: { _id: 0, totalTax: 1 } }
          ],
          templateName: 'b_console_cpy'
        }
      }

      if (industryCode) {
        params.totalCount.cmdList[0].$match['data.industry'] = { $in: [industryCode] }
        params.individual.cmdList[0].$match['data.industry'] = { $in: [industryCode] }
        params.company.cmdList[0].$match['data.industry'] = { $in: [industryCode] }
        params.tax.cmdList[0].$match['data.industry'] = { $in: [industryCode] }
      }

      if (industryTag) {
        params.totalCount.cmdList[0].$match['data.cpyTag'] = { $in: [industryTag] }
        params.individual.cmdList[0].$match['data.cpyTag'] = { $in: [industryTag] }
        params.company.cmdList[0].$match['data.cpyTag'] = { $in: [industryTag] }
        params.tax.cmdList[0].$match['data.cpyTag'] = { $in: [industryTag] }
      }

      aggregateList(params).then(res => {
        const tax = Number(res.result?.tax[0]?.totalTax || 0) / 10000
        dataObj.totalCount = res.result?.totalCount[0]?.count || 0
        dataObj.individual = res.result?.individual[0]?.count || 0
        dataObj.company = res.result?.company[0]?.count || 0
        dataObj.tax = Number(tax.toFixed(0))
      })
    }

    fetchData()

    watch(currentIndustryCode, code => {
      if (code) {
        fetchData({ industryCode: code })
      } else {
        fetchData()
      }
    })
    watch(currentIndustryTag, tag => {
      if (tag) {
        fetchData({ industryTag: tag })
      } else {
        fetchData()
      }
    })

    // getIndustryListLevel0().then(res => {
    //   const list = res.data
    //   const map = {}
    //   list.forEach(d => {
    //     map[d.code] = d.name
    //   })
    //   getInfo(map)
    // })

    // const getInfo = industryMap => {
    //   const params = {
    //     regCpyCounts: {
    //       cmdList: [
    //         { $match: { 'data.registerStreet': { $ne: 'null' } } },
    //         { $group: { _id: 'null', counts: { $sum: 1 } } }
    //       ],
    //       templateName: 'enterprise_information'
    //     },
    //     cpyCounts: {
    //       cmdList: [{ $group: { _id: 'null', counts: { $sum: 1 } } }],
    //       templateName: 'enterprise_information'
    //     },
    //     industryCpyCounts: {
    //       cmdList: [
    //         { $match: { 'data.type': '街道', 'data.year': '2020年' } },
    //         { $group: { _id: '$data.industry', regCpyCounts: { $sum: '$data.regCpyCount' } } },
    //         { $sort: { regCpyCounts: -1 } },
    //         { $limit: 3 }
    //       ],
    //       templateName: 'stats_cpy_information'
    //     },
    //     industryCpyTax: {
    //       cmdList: [
    //         { $match: { 'data.type': '街道', 'data.year': '2020年', 'data.id': { $ne: 'null' } } },
    //         {
    //           $group: {
    //             _id: '$data.industry',
    //             regCpyTax: { $sum: { $toDouble: '$data.regCpyTax' } }
    //           }
    //         },
    //         { $sort: { regCpyTax: -1 } },
    //         { $limit: 3 }
    //       ],
    //       templateName: 'stats_cpy_information'
    //     },
    //     buildingCpyTax: {
    //       cmdList: [
    //         { $match: { 'data.type': '街道', 'data.year': '2020年', 'data.id': { $ne: 'null' } } },
    //         { $group: { _id: 'null', regCpyTax: { $sum: { $toDouble: '$data.regCpyTax' } } } }
    //       ],
    //       templateName: 'stats_cpy_information'
    //     }
    //   }

    //   aggregateList(params).then(res => {
    //     /* eslint-disable no-unused-vars */
    //     const {
    //       buildingInfo,
    //       buildingCpyTax,
    //       industryCpyCounts,
    //       industryCpyTax,
    //       cpyCounts,
    //       regCpyCounts,
    //       streetInfo,
    //       special
    //     } = res.data
    //     const key1 = cpyCounts[0].counts
    //     const key2 = ((regCpyCounts[0].counts / cpyCounts[0].counts) * 100).toFixed(2)
    //     const key3 = industryCpyCounts.map(d => `${industryMap[d.id]}`).join('、')
    //     const key4 = industryCpyCounts
    //       .map(d => `${((d.regCpyCounts / regCpyCounts[0].counts) * 100).toFixed(2)}%`)
    //       .join('、')
    //     const key5 = industryCpyTax.map(d => `${industryMap[d.id]}`).join('、')
    //     const key6 = industryCpyTax
    //       .map(d => `${((d.regCpyTax / buildingCpyTax[0].regCpyTax) * 100).toFixed(2)}%`)
    //       .join('、')

    //     part.value = `全区共有${key1}家在营市场主体，其中注册企业占比${key2}%。按照行业个数分布，${key3}位居前三名，分别占比${key4}；按照全口径税收分布，${key5}位居前三名，分别占比${key6}。`
    //   })
    // }

    const handleDesc = () => {
      dataObj.showDesc = true
    }
    return { dataObj, handleDesc, part }
  }
}
</script>

<style lang="less" scoped>
@bgcColor: #1c1c24;
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;

.left-1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  .total-count {
    .text {
      font-size: 16px;
      margin: 0 10px;
    }

    .count {
      font-size: 30px;
    }
  }

  .key1-key2-key3,
  .key4-key5-key6 {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.desc-title {
  width: 900px;
  height: 500px;
  background-color: #0b0f1a;
  // padding: 20px;
  overflow: hidden;
  padding: 0 30px 30px;

  .title-close {
    height: 75px;
    color: @textColor;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 24px;
    // padding-left: 20px;

    .net-admin-btn {
      display: flex;
      align-items: center;

      .close {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        color: @textColor;
        cursor: pointer;
      }
    }
  }

  .desc-content {
    background-color: #161e31;
    border-radius: 6px;
    height: calc(100% - 75px);
    padding: 45px 30px;

    .part {
      color: #fff;
      font-size: 18px;
      line-height: 2;
      text-indent: 2em;
      letter-spacing: 2px;
    }
  }
}
</style>
