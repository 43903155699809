<template>
  <div class="street-list-wrapper">
    <TabsListLayout
      :industryLabel="industryLabel"
      :tabList="tabList"
      :showDetail="showDetail"
      :labelStyle="{ padding: '0 20px', justifyContent: 'flex-start' }"
    >
      <template #filter>
        <ListSort :dataList="sortData" @onSort="handleSort" />
      </template>

      <template #list>
        <div class="list-view-wrapper infinite-list-wrapper">
          <div
            class="list-item"
            v-for="item in companyList"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            :key="item.id"
          >
            <CompanyListViewItem :dataObj="item" @click="handleItemClick(item)" />
          </div>
          <p v-if="loading">加载中...</p>
          <p v-if="noMore">没有更多了</p>
        </div>

        <!-- <ListView :dataList="companyList" v-infinite-scroll="load" v-slot="{ item }">
          <CompanyListViewItem :dataObj="item.data" @click="handleItemClick(item)" />
        </ListView> -->
      </template>

      <template #detail>
        <BuildingDetail @onClose="hanldeCloseBtn" />
      </template>
    </TabsListLayout>
  </div>
</template>

<script>
import TabsListLayout from '@/layout/TabsListLayout'
import ListSort from '@/components/ListSort'
import CompanyListViewItem from '@/components/ListView/CompanyListViewItem'
// import StreetDetail from '@/views/buildingResources/components/StreetDetail'
import BuildingDetail from '@/components/ListView/BuildingDetail'
import { getCompanyPageList } from '@/api/list'
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

const tabList = [
  {
    label: '企业列表',
    name: 'BuildingDetail',
    type: 2
  }
]

export default {
  name: 'RightPartBT',
  components: {
    TabsListLayout,
    ListSort,
    CompanyListViewItem,
    // StreetDetail
    BuildingDetail
  },
  props: {
    sortData: {
      type: Array,
      default: () => [
        {
          label: '企业名称',
          sort: -1,
          key: 'data.name'
        },
        {
          label: '总税收',
          sort: -1,
          key: 'stats.lastYearTax'
        },
        {
          label: '区税收',
          sort: -1,
          key: 'stats.lastYearQxTax'
        }
      ]
    }
  },
  setup: props => {
    const store = useStore()
    const dataTotal = ref(100)
    const pageNum = ref(1)
    const loading = ref(false)
    const companyList = ref([])
    const sort = ref({})
    const noMore = computed(() => companyList.value.length >= dataTotal.value)
    const disabled = computed(() => loading.value || noMore.value)
    const currentIndustryCode = computed(() => store.state.common.currentIndustryCode)
    const currentIndustryLabel = computed(() => store.state.common.currentIndustryLabel)
    const currentIndustryTag = computed(() => store.state.common.currentIndustryTag)
    const industryLabel = ref()

    const showDetail = ref(false)

    const commonParams = {}

    const load = () => {
      loading.value = true
      pageNum.value++
      fetchData()
    }

    const handleItemClick = item => {
      // store.commit(SET_CURRENT_BUILDING_ID, item.id)
      // showDetail.value = true
    }

    const hanldeCloseBtn = () => {
      showDetail.value = false
    }

    const handleSort = item => {
      sort.value = { [item.key]: item.sort }
    }

    const fetchData = async () => {
      const params = {
        conditions: { stats: { $exists: true } },
        // conditions: {
        //   'data.regStatusLabel': '在营（开业）企业',
        //   'data.type': { $in: ['企业', '个体户'] }
        // },
        sort: sort.value,
        fields: {
          data: { name: true },
          stats: true
        },
        pageInfo: {
          pageNum: pageNum.value,
          pageSize: 20
        }
      }

      if (commonParams.industryCode) {
        params.conditions['data.industry'] = { $in: [commonParams.industryCode] }
      }

      if (commonParams.industryTag) {
        params.conditions['data.cpyTag'] = { $in: [commonParams.industryTag] }
      }

      const {
        code,
        data: { total, list }
      } = await getCompanyPageList(params)

      if (code === '200') {
        dataTotal.value = total
        companyList.value = [...companyList.value, ...list]
        loading.value = false
      }
    }

    watch(sort, s => {
      pageNum.value = 1
      companyList.value.length = 0
      fetchData()
    })

    fetchData()

    watch(currentIndustryCode, code => {
      pageNum.value = 1
      companyList.value.length = 0
      commonParams.industryCode = code
      fetchData()
    })

    watch(currentIndustryTag, tag => {
      pageNum.value = 1
      companyList.value.length = 0
      commonParams.industryTag = tag
      console.log(tag)
      industryLabel.value = tag
      fetchData()
    })

    watch(currentIndustryLabel, label => {
      industryLabel.value = label
    })

    return {
      load,
      noMore,
      loading,
      disabled,
      companyList,
      tabList,
      handleSort,
      showDetail,
      handleItemClick,
      hanldeCloseBtn,
      industryLabel
    }
  }
}
</script>

<style lang="less" scoped>
@textColor: #fafafb;
@titleBgc: #1b1c1c;

.street-list-wrapper {
  height: 100%;
}

.detail-box {
  height: 100%;

  .title-close {
    height: 75px;
    color: @textColor;
    // background-color: @titleBgc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 24px;
    padding-left: 20px;

    .close {
      width: 20px;
      cursor: pointer;
    }
  }

  .detail-content {
    height: calc(100% - 75px);
  }
}

.list-view-wrapper {
  height: 100%;
  .list-item {
    cursor: pointer;
    margin: 0 -10px;
  }
}
</style>
