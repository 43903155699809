<template>
  <div class="cloud-map-wrapper">
    <LCRLayout>
      <template #left>
        <LeftPart />
      </template>

      <template #center>
        <CenterPart />
      </template>

      <template #right>
        <RightPart />
      </template>
    </LCRLayout>
  </div>
</template>

<script>
// import SubMenu from '@/components/SubMenu'
import LCRLayout from '@/layout/LCRLayout'
import CenterPart from './CenterPart'
import LeftPart from './LeftPart'
import RightPart from './RightPart'
import useLoading from '@/hooks/useLoading'
import { useStore } from 'vuex'

export default {
  name: 'HomeLayout',
  components: { LCRLayout, LeftPart, CenterPart, RightPart },

  setup: () => {
    const store = useStore()
    const { hidLoading } = useLoading()
    hidLoading()

    store.dispatch('getIndustryLevel0')
    return {}
  }
}
</script>

<style lang="less" scoped>
.cloud-map-wrapper {
  height: 100%;
}
</style>
