<template>
  <TitleContentPanel title="行业分布" height="100%">
    <template #append>
      <span @click="showDesc = true">详情</span>
    </template>

    <div class="left-2-wrapper">
      <div class="chart-dom" ref="chartDom"></div>
      <!-- <VEchart :option="option" @legendselectchanged="handleChartClick" /> -->
    </div>

    <template #reset>
      <el-button type="text" @click="handleReset">重置</el-button>
    </template>
  </TitleContentPanel>

  <Modal :isShow="showDesc">
    <IndustryChart @onClose="handleModalClose" />
  </Modal>
</template>

<script>
import TitleContentPanel from '@/components/TitleContentPanel'
import Modal from '@/components/Modal'
import IndustryChart from './IndustryChart'
import { ref, computed, onMounted, watch } from 'vue'
import { chartColors } from '@/config/commonConfig'
import { useStore } from 'vuex'
import { aggregate } from '@/api/aggregate2'
import { formatTax } from '@/utils/util'
import * as echarts from 'echarts'
import {
  SET_CURRENT_INDUSTRY_CODE,
  SET_CURRENT_INDUSTRY_LABEL,
  SET_CURRENT_INDUSTRY_TAG
} from '@/store/mutation-types'
import dbMap from '@/api/dbMap'
export default {
  name: 'Left2',
  components: { TitleContentPanel, IndustryChart, Modal },
  setup: () => {
    const store = useStore()
    const showDesc = ref(false)
    const chartDom = ref()
    const chartInstance = ref()
    const currentIndustryLabel = ref()
    const industryLevel0 = computed(() => store.state.common.industryLevel0)
    const currentCompanyKey = computed(() => store.state.cloudMap.currentCompanyKey)
    const option = ref({
      backgroundColor: '#1a1a1a',
      color: chartColors,
      grid: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        show: true,
        backgroundColor: '#1a1a1a',
        borderColor: '#1a1a1a'
      },
      tooltip: {
        show: false
        // trigger: 'item'
      },
      legend: {
        itemWidth: 10,
        itemHeight: 10,
        type: 'scroll',
        orient: 'vertical',
        left: 10,
        top: 10,
        bottom: 20,
        icon: 'circle',
        textStyle: { color: '#fff' },
        selected: {},
        // selectedMode: false,
        formatter: name => {
          if (name.length > 7) {
            return name.substr(0, 7) + '...'
          }
          return name
        }
      },
      series: [
        {
          minAngle: 5,
          name: '行业分布',
          type: 'pie',
          center: ['70%', '45%'],
          radius: ['55%', '65%'],
          label: {
            show: false,
            formatter: '{b} \n {d}%',
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '16'
              // fontWeight: 'bold'
            },
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          data: []
        }
      ]
    })
    let myChart, chartData

    onMounted(async () => {
      myChart = echarts.init(chartDom.value)
      chartInstance.value = myChart
      chartData = await getChartData()

      option.value.series[0].data = chartData
      myChart.setOption(option.value)

      myChart.on('legendselectchanged', e => {
        store.commit(SET_CURRENT_INDUSTRY_TAG, '')
        currentIndustryLabel.value = e.name
        const code = chartData.find(d => d.name === e.name).code
        store.commit(SET_CURRENT_INDUSTRY_LABEL, e.name)
        store.commit(SET_CURRENT_INDUSTRY_CODE, code)
        option.value.legend.selected = Object.keys(e.selected).map(d => ({
          [d]: true
        }))
        myChart.setOption(option.value)
      })
    })

    watch(currentIndustryLabel, label => {
      // setTimeout(() => {
      //   chartInstance.value.dispatchAction({
      //     type: 'downplay',
      //     seriesIndex: 0
      //   })
      // }, 3000)
    })

    watch(currentCompanyKey, key => {
      chartData = chartData.map(d => {
        if (key === 0) {
          d.value = d.cnt
        } else if (key === 1) {
          d.value = d.totalTax
        }
        return d
      })
      option.value.series[0].data = chartData
      myChart.setOption(option.value)
    })

    const getChartData = async key => {
      // const params = {
      //   count: {
      //     cmdList: [
      //       { $match: { 'data.industry': { $exists: true } } },
      //       {
      //         $project: {
      //           industry: { $arrayElemAt: ['$data.industry', 0] },
      //           tax: '$stats.lastYearTax'
      //         }
      //       },
      //       {
      //         $group: {
      //           // _id: { industry: '$industry' }
      //           // cnt: { $sum: 1 }
      //           // totalTax: { $sum: '$tax' }
      //         }
      //       }
      //       // { $project: { industry: '$_id.industry', cnt: 1, totalTax: 1, _id: 0 } }
      //       // { $match: { $and: [{ industry: { $nin: [null], $exists: true } }] } }
      //     ],
      //     templateName: dbMap.B_CONSOLE_COMPANY
      //   }
      // }
      const otherCode = ['N', 'A', 'D', 'B', 'S', 'T']
      const res = await aggregate({
        cmdList: [
          // { $match: { 'data.industry': { $exists: true } } },
          {
            $match: {
              $and: [
                {
                  'data.industry': {
                    $nin: ['null', [], ''],
                    $type: 'array'
                  }
                }
              ]
            }
          },
          {
            $project: {
              industry: { $arrayElemAt: ['$data.industry', 0] }
              // tax: '$stats.lastYearTax'
            }
          },
          {
            $group: {
              _id: { industry: '$industry' },
              cnt: { $sum: 1 },
              totalTax: { $sum: '$tax' }
            }
          },
          { $project: { industry: '$_id.industry', cnt: 1, totalTax: 1, _id: 0 } }
          // { $match: { $and: [{ industry: { $nin: [null], $exists: true } }] } }
        ],
        templateName: dbMap.B_CONSOLE_COMPANY
      })

      const count = res.result || {}

      const temp = industryLevel0.value.map(d => {
        const current = count.find(f => f.industry === d.code)
        return {
          name: d.name,
          value: (current && current.cnt) || 0,
          cnt: (current && current.cnt) || 0,
          totalTax: (current && formatTax(current.totalTax)) || 0,
          code: d.code
        }
      })

      const other = temp
        .filter(d => otherCode.includes(d.code))
        .reduce(
          (a, b) => {
            return {
              name: '其他行业',
              value: a.value + b.value,
              cnt: a.cnt + b.cnt,
              totalTax: a.totalTax + b.totalTax
            }
          },
          { value: 0, cnt: 0, totalTax: 9 }
        )

      const temp1 = temp.filter(d => !otherCode.includes(d.code))

      return [...temp1.sort((a, b) => b.value - a.value), other]
    }

    const handleModalClose = () => {
      showDesc.value = false
      store.commit(SET_CURRENT_INDUSTRY_CODE, '')
      store.commit(SET_CURRENT_INDUSTRY_LABEL, '')
    }

    const handleReset = () => {
      store.commit(SET_CURRENT_INDUSTRY_LABEL, '')
      store.commit(SET_CURRENT_INDUSTRY_CODE, '')
    }

    return { option, showDesc, handleModalClose, chartDom, handleReset }
  }
}
</script>

<style lang="less" scoped>
.left-2-wrapper {
  height: 100%;

  .chart-dom {
    height: 100%;
  }
}
</style>
