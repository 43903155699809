<template>
  <div class="center-part-wrapper">
    <!-- mapStyle="amap://styles/darkblue" -->
    <AMap
      mapStyle="amap://styles/3c5b5047c37621e5b97d427e94ccc67f"
      :aMapKey="AMAP_KEY"
      :zoom="13.2"
      :showLabel="false"
      :center="[120.363012, 36.065753]"
      viewMode="3D"
      :plugins="['GeoJSON']"
      :isReady="isReady"
      :loadingText="loadingText"
      @mapLoaded="handleMapLoaded"
    />

    <div class="menu-box" v-show="!showPoint">
      <!-- <SubMenuWithSelect /> -->
    </div>
    <!-- <div class="show-point-heatmap">
      <el-radio-group v-model="showPoint">
        <el-radio :label="false">企业热力图</el-radio>
        <el-radio :label="true">企业分布图</el-radio>
      </el-radio-group>
    </div> -->
    <!-- <CompanyLegend /> -->

    <div class="map-legend">
      <MapLegend />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import AMap from '@/components/AMap'
import { AMAP_KEY } from '@/config/commonConfig'
import { ref, onBeforeUnmount, watch } from 'vue'
import { useLoca, useHeatMap, useStreet } from '@/hooks'
import { getCompanyGeoJson, getStreetAreaJson } from '@/api/map'
import { useStore } from 'vuex'
// import CompanyLegend from './components/CompanyLegend.vue'
// import SubMenuWithSelect from './components/SubMenuWithSelect.vue'
import MapLegend from './components/MapLegend.vue'

export default {
  name: 'Center1',
  components: { AMap, MapLegend },
  props: {},
  setup: () => {
    // eslint-disable-next-line no-unused-vars
    let locaObj, pointLayer, heatMapLayer
    const isReady = ref(false)
    const showPoint = ref(false)
    const loadingText = ref(undefined)
    const store = useStore()
    const partMap = {}
    const getCompanyLst = () => store.dispatch('getCompanyList')

    const handleMapLoaded = ({ map, AMap }) => {
      const { loca } = useLoca(map)
      locaObj = window.locaObj = loca
      loadingText.value = '数据加载中...'
      Promise.all([getCompanyLst(), getStreetAreaJson()]).then(([companyData, streetData]) => {
        useStreet(map, AMap, loca, streetData, {
          polygonZoom: [2, 20],
          showIcons: false,
          showHover: false
        })
        const { layer: heatMap } = useHeatMap(loca, companyData, true)
        // const { layer: point } = useCompanyPoint(loca, companyData, false)
        // partMap.pointLayer = point
        partMap.heatMapLayer = heatMap
        isReady.value = true
      })
    }

    // const hidAll = () => {
    // partMap.pointLayer.hide()
    //   partMap.heatMapLayer.hide()
    // }

    // watch(showPoint, val => {
    //   hidAll()
    //   if (val) {
    //     partMap.pointLayer.show()
    //   } else {
    //     partMap.heatMapLayer.show()
    //   }
    // })

    onBeforeUnmount(() => {
      // 页面离开时需要销毁，否则绘制有问题
      // locaObj && locaObj.animate.stop()
      locaObj && locaObj.destroy()
      Object.keys(partMap).forEach(partName => {
        const layer = partMap[partName]
        layer.unwatch && layer.unwatch()
        layer.unwatch2 && layer.unwatch2()
      })
    })

    return { isReady, AMAP_KEY, handleMapLoaded, loadingText, showPoint }
  }
}
</script>

<style lang="less" scoped>
.center-part-wrapper {
  height: 100%;
  position: relative;

  .show-point-heatmap {
    position: absolute;
    right: 10px;
    bottom: 10px;

    .el-radio-group {
      color: white;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: #161e31;

      .el-radio {
        margin-right: 0;
        margin-bottom: 10px;
        color: whitesmoke;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu-box {
    // height: 70px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-color: #0b0f1a;
    border-radius: 10px;
  }
}
</style>
