<template>
  <div class="left-part-wrapper">
    <div class="legend">
      <CompanyLegend />
    </div>
    <div class="street-list">
      <!-- <component :is="currentList" :sortData="sortData" /> -->
      <CompanyList />
    </div>
  </div>
</template>

<script>
import CompanyList from '@/components/ListView/CompanyList'
import CompanyLegend from './components/CompanyLegend.vue'
// import { computed } from 'vue'
// import { useStore } from 'vuex'

export default {
  name: 'RightPart',
  components: { CompanyList, CompanyLegend },

  setup: () => {
    // const store = useStore()

    // const currentList = computed(() => store.state.industrialEconomy.currentMenu)
    return {}
  }
}
</script>

<style lang="less" scoped>
.left-part-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .legend {
    // height: 100px;
  }

  .street-list {
    // height: 100%;
    flex: 1;
    overflow: hidden;
  }
}
</style>
