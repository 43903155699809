<template>
  <div class="company-list-view-item-wrapper">
    <div class="left">
      <div class="index">{{ dataObj.data.name && dataObj.data.name.substr(0, 1) }}</div>
      <div class="name">{{ dataObj.data.name }}</div>
    </div>
    <div class="right">
      <LabelValueUnit label="总税收" :value="formatTax(dataObj.stats?.lastYearTax).value" unit="" />
      <LabelValueUnit
        label="区税收"
        :value="formatTax(dataObj.stats?.lastYearQxTax).value"
        unit=""
      />
      <!-- <div class="info">
        <span class="building-count">企业：{{ dataObj.numberOfCompanies || 1000 }} 家</span>
        <span class="total-area">税收：{{ dataObj.taxOutputValue || 5000 }} 万元</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import LabelValueUnit from '@/components/ListView/LabelValueUnit.vue'
import { formatCompanyTax as formatTax } from '@/utils/util'
export default {
  name: 'ListViewItem',
  components: { LabelValueUnit },
  props: {
    dataObj: Object,
    active: Boolean
  },
  setup: props => {
    return { formatTax }
  }
}
</script>

<style lang="less" scoped>
@titleColor: #fafafb;
@infoColor: #fff;
@keyInfoColor: #0091ff;
@leftBgc: #1e75ff;
@hoverColor: lighten(#1c1c24, 10%);

.company-list-view-item-wrapper {
  padding: 18px 10px;
  border-top: 1px solid #070b15;
  cursor: pointer;
  height: 90px;

  display: flex;
  align-items: center;
  border-top: 1px solid #070b15;
  border-bottom: 1px solid #070b15;

  .left {
    flex: 3;
    padding-right: 10px;
    display: flex;
    align-items: center;

    .index {
      width: 38px;
      height: 38px;
      background-color: #1e75ff;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      border-radius: 10px;
      margin-right: 18px;
    }

    .name {
      flex: 1;
      color: #fafafb;
      line-height: 1.2;
    }
  }

  .right {
    flex: 2;
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    background-color: @hoverColor;
  }
}

.active {
  background-color: @hoverColor;
}
</style>
