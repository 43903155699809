<template>
  <div class="legend-wrapper">
    <div class="title">税收等级(万元)</div>
    <div class="range-list">
      <div class="range-item" v-for="(item, index) in dataList" :key="index">
        <div class="label">{{ item.label }}</div>
        <div class="range">
          <span class="min range-item">{{ item.min }}</span>
          <span class="line" v-if="item.max">-</span>
          <span class="max range-item" v-if="item.max">{{ item.max }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ref, watchEffect } from 'vue'
import { formatCompanyTax as formatTax } from '@/utils/util'
export default {
  name: 'myLegend',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000
    }
  },
  setup: props => {
    const dataList = ref([])

    watchEffect(() => {
      const scale = formatTax().scale

      const arr = scale.ticks(5)

      const len = arr.length
      dataList.value = arr
        .map((d, i) => {
          if (i === len - 2) {
            return {
              min: `${d}以上`,
              max: 0,
              label: scale(arr[i + 1])
            }
          }
          return {
            min: d,
            max: arr[i + 1] - 1,
            label: scale(arr[i + 1] - 1)
          }
        })
        .slice(0, len - 1)
    })

    return { dataList }
  }
}
</script>

<style lang="less" scoped>
.legend-wrapper {
  // position: absolute;
  // top: 20px;
  // right: 20px;
  min-width: 170px;
  background: fade(#1a1a1a, 85%);
  border-radius: 10px;
  padding: 15px;
  // padding-right: 200px;
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 79, 0.1);

  .title {
    color: #fff;
    margin-bottom: 10px;
  }

  .range-list {
    .range-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;

      .color {
        width: 16px;
        height: 8px;
        border-radius: 6px;
        margin-right: 10px;
      }

      .range {
        display: flex;
        justify-content: space-around;
        .line {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
