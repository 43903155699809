<template>
  <div class="legend-wrapper">
    <div class="title">{{ label }}</div>
    <div class="range-list">
      <div class="range-item" v-for="(item, index) in dataList" :key="index">
        <div class="color" :style="{ background: item.color }"></div>
        <div class="range">
          <span class="min range-item">{{ item.min }}</span>
          <span class="line" v-if="item.max">-</span>
          <span class="max range-item" v-if="item.max">{{ item.max }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { scaleSequential } from 'd3-scale'
import { interpolateRdYlGn } from 'd3-scale-chromatic'
import { ref, watchEffect, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'myLegend',
  props: {
    min: {
      type: Number,
      default: 0
    },
    more: {
      type: Boolean,
      default: true
    }
  },
  setup: props => {
    const store = useStore()
    const dataList = ref([])
    const max = ref(100)
    const label = ref('企业数（家）')
    const currentCompanyKey = computed(() => store.state.cloudMap.currentCompanyKey)

    watchEffect(() => {
      if (currentCompanyKey.value === 0) {
        label.value = '企业数（家）'
        max.value = 100
      } else {
        label.value = '税收（万元）'
        max.value = 10000
      }

      const colorScale = scaleSequential(t => interpolateRdYlGn(1 - t))
        .domain([0, max.value])
        .nice()

      const arr = colorScale.ticks(5)

      const len = arr.length
      const temp = arr.map((d, i) => {
        if (i === len - 2) {
          return {
            min: d,
            max: arr[i + 1],
            color: colorScale(arr[i + 1])
          }
        }

        if (i === len - 1) {
          return {
            min: `${d}以上`,
            max: '',
            color: '#f00'
          }
        }

        return {
          min: d,
          max: arr[i + 1] - 1,
          color: colorScale(arr[i + 1] - 1)
        }
      })
      // .slice(0, len - 1)

      if (props.more) {
        dataList.value = temp
      } else {
        dataList.value = temp.slice(0, len - 1)
      }
    })

    // watch(more, val=>{

    // })

    console.log(dataList)
    return { dataList, label }
  }
}
</script>

<style lang="less" scoped>
.legend-wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
  min-width: 170px;
  background: fade(#1a1a1a, 85%);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 5px 15px 0px rgba(68, 68, 79, 0.1);

  .title {
    color: #fff;
    margin-bottom: 10px;
  }

  .range-list {
    .range-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;

      .color {
        width: 16px;
        height: 8px;
        border-radius: 6px;
        margin-right: 10px;
      }

      .range {
        display: flex;
        justify-content: space-around;
        .line {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
