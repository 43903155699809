<template>
  <div class="industry-tag-chart-wrapper">
    <div class="desc-title">
      <div class="title-close">
        <span class="title">市南区产业分布基本情况</span>
        <div class="net-admin-btn">
          <Icon class="close" name="close" @click="handleClose" />
        </div>
      </div>

      <div class="desc-content">
        <div class="left-part">
          <VEchart :option="option" @click="handleChartClick" />
        </div>
        <div class="right-part">
          <CompanyList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/IconSvg'
import CompanyList from '@/components/ListView/CompanyList.vue'
import { chartColors } from '@/config/commonConfig'
import { aggregateList } from '@/api/aggregate'
import { onMounted, ref } from 'vue'
import { formatTax } from '@/utils/util'
import { SET_CURRENT_INDUSTRY_TAG } from '@/store/mutation-types'
import { useStore } from 'vuex'
export default {
  name: 'IndustryTagChart',
  emits: ['onClose'],
  components: { Icon, CompanyList },
  setup: (props, { emit }) => {
    const store = useStore()

    const option = ref({
      backgroundColor: '#1a1a1a',
      color: chartColors,
      legend: {
        left: 'center',
        top: 20,
        textStyle: {
          color: '#fff'
        }
      },
      grid: {
        top: 100,
        left: 20,
        bottom: 20,
        right: 20,
        // show: true,
        // borderColor: '#161e31',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#1e75ff'
          }
        },
        formatter: node => {
          return `
          <div>${node[0].name}</div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <div style="margin-right: 20px">
              <span style="display:inline-block;width:10px;height:10px;background:${
                node[0].color
              };border-radius:50%"></span>
              <span>${node[0].seriesName}</span>
            </div>
            <div>
              <b style="font-weight: bold">${node[0].value}</b>（${node[0].data.rate.toFixed(2)}%）
            </div>
          </div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <div style="margin-right: 20px">
              <span style="display:inline-block;width:10px;height:10px;background:${
                node[1].color
              };border-radius:50%"></span>
              <span>${node[1].seriesName}</span>
            </div>
            <div>
              <b style="font-weight: bold">${node[1].value}</b> （${node[1].data.rate.toFixed(2)}%）
            </div>
          </div>
          `
        }
      },
      xAxis: {
        type: 'category',
        // boundaryGap: false,
        data: [],
        axisLabel: { interval: 0, color: '#fff', rotate: 45 }
      },
      yAxis: [
        {
          type: 'value',
          name: '企业数（家）',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#242D3B'
            }
          },
          axisLabel: {
            color: '#fff'
          }
        },
        {
          type: 'value',
          name: '税收数（万元）',
          splitLine: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          }
        }
      ],
      series: [
        {
          name: '企业数',
          smooth: true,
          data: [],
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: node => {
              return node.data.rate.toFixed(2) + '%'
            }
          }
        },
        {
          name: '税收数',
          smooth: true,
          data: [],
          type: 'bar',
          yAxisIndex: 1,
          label: {
            show: true,
            position: 'top',
            formatter: node => {
              return node.data.rate.toFixed(2) + '%'
            }
          }
        }
      ]
    })

    const fetchData = (options = {}) => {
      const params = {
        dataList: {
          cmdList: [
            { $unwind: '$data.cpyTag' },
            {
              $group: {
                _id: '$data.cpyTag',
                count: { $sum: 1 },
                totalTax: { $sum: '$stats.lastYearTax' }
              }
            },
            {
              $match: {
                _id: {
                  $in: [
                    '总部经济',
                    '航运贸易',
                    '文化旅游',
                    '商贸商务',
                    '数字经济',
                    '现代金融',
                    '其它'
                  ]
                }
              }
            }
          ],
          templateName: 'enterprise_information'
        },
        count: {
          cmdList: [{ $match: { 'data.industry': { $exists: true } } }, { $count: 'count' }],
          templateName: 'enterprise_information'
        },
        totalTax: {
          cmdList: [{ $group: { _id: '', count: { $sum: '$stats.lastYearTax' } } }],
          templateName: 'enterprise_information'
        }
      }
      aggregateList(params).then(res => {
        const list = res.data.dataList
        const totalCompany = list.reduce((a, b) => {
          return { count: a.count + b.count }
        }).count
        const totalTax = list.reduce((a, b) => {
          return { totalTax: a.totalTax + b.totalTax }
        }).totalTax

        const temp = list.map(d => {
          return {
            name: d.id,
            value1: d.count || 0,
            rate1: ((d.count || 0) / totalCompany) * 100,
            value2: formatTax(d.totalTax),
            rate2: ((d.totalTax || 0) / totalTax) * 100
          }
        })

        const data = temp.sort((a, b) => b.value1 - a.value1)

        const data1 = data.map(d => {
          return {
            name: d.name,
            value: d.value1,
            rate: d.rate1
          }
        })
        const data2 = data.map(d => {
          return {
            name: d.name,
            value: d.value2,
            rate: d.rate2
          }
        })

        option.value.series[0].data = data1
        option.value.series[1].data = data2
        option.value.xAxis.data = data1.map(d => d.name)
      })
    }

    onMounted(() => {
      fetchData()
    })

    const handleClose = () => {
      emit('onClose')
    }

    const handleChartClick = params => {
      console.log(params)
      const tag = params.data.name
      store.commit(SET_CURRENT_INDUSTRY_TAG, tag)
    }

    return { handleClose, option, handleChartClick }
  }
}
</script>

<style lang="less" scoped>
@bgcColor: #1c1c24;
@textColor: fade(#fff, 85%);
@titleBgc: #1b1c1c;
.industry-tag-chart-wrapper {
  .desc-title {
    width: 90vw;
    height: 90vh;
    background-color: #0b0f1a;
    // padding: 20px;
    overflow: hidden;
    padding: 0 30px 30px;

    .title-close {
      height: 75px;
      color: @textColor;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 24px;
      // padding-left: 20px;

      .net-admin-btn {
        display: flex;
        align-items: center;

        .close {
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
          color: @textColor;
          cursor: pointer;
        }
      }
    }

    .desc-content {
      height: calc(100% - 75px);
      display: flex;

      .left-part {
        flex: 1;
        min-width: 800px;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        margin-right: 20px;
        background-color: @titleBgc;
      }

      .right-part {
        flex: none;
        border-radius: 20px;
        width: 400px;
        height: 100%;
      }
    }
  }
}
</style>
